import {isEqual} from "lodash";

export const STATE_TRIGGERS = {
    APPROVE: 'APPROVE',
    LOCK: 'LOCK',
    UNLOCK: 'UNLOCK'
}

export const STATES = {
    APPROVED: {
        key: 'APPROVED',
        label: 'Approved',
        color: 'green',
        icon: 'fal fa-check'
    },
    LOCKED_APPROVED: {
        key: 'LOCKED_APPROVED',
        label: 'Locked approved',
        color: 'orange',
        icon: 'fal fa-lock'
    },
    LOCKED_INITIAL: {
        key: 'LOCKED_INITIAL',
        label: 'Locked initial',
        color: 'gray',
        icon: 'fal fa-lock'
    },
    INITIAL: {
        key: 'INITIAL',
        label: 'Initial',
        color: 'gray',
        icon: 'fal fa-circle',
    }
};

export const generateNewState = (current, trigger) => {
    switch (trigger) {
        case STATE_TRIGGERS.APPROVE:
        case STATE_TRIGGERS.UNLOCK:
            return STATES.APPROVED.key;
        case STATE_TRIGGERS.LOCK:
            return current.includes('LOCKED_') ? current : 'LOCKED_' + current
        default:
            return current
    }
}

export const generateTriggers = states => {
    let triggers = []

    // INITIAL - can be LOCK or APPROVE
    if (isEqual(states.sort(), [STATES.INITIAL.key])) {
        triggers = [{trigger: STATE_TRIGGERS.APPROVE, icon: 'fal fa-check'}, {trigger: STATE_TRIGGERS.LOCK, icon: 'fal fa-lock'}]
    }

    // LOCKED_INITIAL - can be APPROVE
    if (isEqual(states.sort(), [STATES.LOCKED_INITIAL.key])) {
        triggers = [{trigger: STATE_TRIGGERS.APPROVE, icon: 'fal fa-check'}]
    }

    // INITIAL and LOCKED_INITIAL - can be APPROVE
    if (isEqual(states.sort(), [STATES.LOCKED_INITIAL.key, STATES.INITIAL.key].sort())) {
        triggers = [{trigger: STATE_TRIGGERS.APPROVE, icon: 'fal fa-check'}]
    }

    // LOCKED_APPROVED - can be UNLOCK
    if (isEqual(states.sort(), [STATES.LOCKED_APPROVED.key])) {
        triggers = [{trigger: STATE_TRIGGERS.UNLOCK, icon: 'fal fa-check'}]
    }

    // APPROVED and INITIAL - can be LOCK
    if (isEqual(states.sort(), [STATES.APPROVED.key, STATES.INITIAL.key].sort())) {
        triggers = [{trigger: STATE_TRIGGERS.LOCK, icon: 'fal fa-lock'}]
    }

    // APPROVED - can be LOCK
    if (isEqual(states.sort(), [STATES.APPROVED.key])) {
        triggers = [{trigger: STATE_TRIGGERS.LOCK, icon: 'fal fa-lock'}]
    }

    return triggers
}